.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  margin-top: 1em;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img {
  border-radius: 10px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 black;
}

.container {
  padding: 2px 16px;
}

.card-border {
  padding: 30px 30px 30px 30px;
}

.card-content-info {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  height: auto;
  font-weight: 500;
}

.card-content {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.card-content-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: .9em;
  font-weight: 600;
  margin-top: .4em;
  margin-bottom: .8em;
}

.card-info-title {
  text-align: center;
  margin-top: .2em;
  font-size: 1.3em;
  font-weight: 700;
}

.card-info {
  text-align: none;
  font-weight: 500;
  font-size: 0.9em;
  margin-bottom: 1em;
}

.img-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, .4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-titles {
  position: absolute;
  width: 100%;
  display: block;
  padding: 40px 40px 40px 40px;
  box-sizing: border-box;

  padding-bottom: .15em;
  color: white;
  text-shadow: 5px 5px 5px black;
  left: 0;
  text-align: left;
}

.card-title {
  bottom: .4em;
  font-size: 3em;
  font-weight: 600;
}

.card-tools {
  bottom: .5em;
  font-size: 1em;
  font-weight: 450;
}

.card-link {
  position: relative;
  text-align: right;
  margin-bottom: 10px;
}