.master-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.main-container {
    margin-top: 20em;
    width: 100%;
    background-color: #eeeeee;
    color: black;
    z-index: 2;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
}

.facts {
    display: flex;
    justify-content: space-between;
}

.intro-cards {
    padding-inline-start: 2em;
    padding-inline-end: 2em;

    display: flex;
    flex-wrap: wrap;
}