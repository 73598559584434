.nav{
    background-color: #25262b;
    position: fixed;
    width: calc(100% - 3rem);
    top: 0;
    overflow: hidden;
    z-index: 2;
    padding: 0 1em 0 1em;
    width: 100%;
    height: 3em;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.nav-name{
    text-decoration: none;
    color: white;
    font-weight: 400;
    font-size: 2vmax;
    letter-spacing: 0.1em;
}

.nav-items {
    display: flex;
}
.nav-item {
    text-decoration: none;
    flex-direction: row;
    font-weight: 400;
    font-size: 2vmax;
    margin-right: 1em;
    color: white;
    letter-spacing: 0.05em;
}

