html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

#basketball-ball {
  position: fixed;
  top: 25%;
  height: 14em;
  z-index: 1;
}

.basketball-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .basketball-ball.active {
    animation: ball-spin infinite 10s linear;
  }
}

.App-header {
  width: 100%;
  background-color: #25262b;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes ball-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.scroll-down {
  position: absolute;
  height: 50px;
  width: 80px;
  border: 3px solid white;
  bottom: 20px;
  border-radius: 30px;
}

.scroll-down::before,
.scroll-down::after {
  content: '';
  position: absolute;
  height: 15px;
  width: 15px;
  border: 3px solid white;
  bottom: 20px;
  transform: translate(-50%, -60%) rotate(45deg);
  border-top: transparent;
  border-left: transparent;
  animation: scroll-down 1s ease-in-out infinite;
}

.scroll-down::after {
  animation-delay: .3s;
}

@keyframes scroll-down {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    top: 60%;
  }
}

.name-container {
  margin-top: 24em;
}

.Typewriter__wrapper {
  text-decoration: none;
  color: white;
  font-weight: 400;
  font-size: 2em;
  letter-spacing: 0.1em;
}

.Typewriter__cursor {
  text-decoration: none;
  animation: Typewriter-cursor 1s infinite;
  margin-left: 1px;
  font-size: 1.5em;
}

.typewriter-container {
  margin-top: 50em;
  /* position: relative;
  bottom: 0; */
}