.footer {
    background-color: black;
    position: relative;
    bottom: 0;
    overflow: hidden;
    padding: 0 1em 0 1em;
    width: 100%;
    height: 3em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2em;
}

.footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 10em;
}

.single-link {
    filter: invert(1);
    width: 1.5em;
    height: 1.5em;
}