.master-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.intro-container {
    margin-top: 14em;
    width: 100%;
    background-color: #eeeeee;
    color: black;
    z-index: 2;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
}

.experience-container {
    margin-top: 20em;
    width: 100%;
    background-color: #eeeeee;
    color: black;
    z-index: 2;
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
}

.starting_line {
    text-align: 'left'
}

h1 {
    margin-block-start: 1em;
    margin-block-end: 1em;
}

h3 {
    margin-block-start: .5em;
    margin-block-end: .05em;
}

.intro-text {
    width: 80%;
}

.intro-name {
    font-family: Verdana, Geneva, Tahoma, sans-serif
}

.facts {
    display: flex;
    justify-content: space-evenly;
}

.fact {
    width: 12em;
}

.intro-cards {
    display: flex;
    justify-content: space-around;
}